import React, { createContext, useContext, useEffect, useRef, useState } from 'react';
import { useAuth } from './AuthContext';
import { JS0N } from './ui/LoadingIndicator';
import { Alert, Card, Button, Container, Row, Col } from 'react-bootstrap';
import { useTheme } from '../ThemeContext';
import useSound from 'use-sound';

const WebSocketContext = createContext(null);

export const WebSocketProvider = ({ children }) => {
  const { theme } = useTheme();
  const { token, userInfo, isSoundEnabled, setIsLoading, logout, getFilters, filters } = useAuth();
  const ws = useRef(null);
  const reconnectTimeout = useRef(null);
  const playSoundRef = useRef(null);
  const [isConnected, setIsConnected] = useState(null);
  const [shouldReconnect, setShouldReconnect] = useState(true);
  const [connectionStatus, setConnectionStatus] = useState('disconnected');
  const [subscriptionEnded, setSubscriptionEnded] = useState(false);
  const isFetchingRef = useRef(false);
  const [sales, setSales] = useState([]);
  const [play] = useSound('/notifsound.mp3');

  useEffect(() => {
    playSoundRef.current = play;
  }, [play]);

  useEffect(() => {
    const fetchFilters = async () => {
      try {
        await getFilters();  // Загружаем фильтры
        console.log('Фильтры успешно загружены:', filters);  // Убедимся, что фильтры загрузились
      } catch (error) {
        console.error('Ошибка при получении фильтров:', error);
      }
    };

    fetchFilters();
  }, []);

  useEffect(() => {
    const connectWebSocket = () => {
      if (!token || !userInfo?.status) {
        console.log('No token or user info available.');
        return;
      }
      if (!filters || filters.length === 0) {
        console.log('Фильтры еще не загружены.');
        return;
      }

      if (ws.current && (ws.current.readyState === WebSocket.OPEN || ws.current.readyState === WebSocket.CONNECTING)) {
        console.log('WebSocket already open or connecting.');
        return;
      }

      ws.current = new WebSocket(`wss://pandoras-box.salew-bot.ru/ws/pwa?mishura=${JS0N.stringify(token)}&user_id=${userInfo.user_id}`);

      ws.current.onopen = () => {
        console.log('WebSocket connected');

        setIsConnected(true);
        setShouldReconnect(true);
        setConnectionStatus('connected');
        setSubscriptionEnded(false);
        ws.current.send(JSON.stringify({ type: 'getLastSales' }));
      };

      ws.current.onmessage = (message) => {
        const data = JSON.parse(message.data);
        if (data.type === 'sale' && data.dataApp) {
          data.dataApp.forEach((sale) => {
            console.log(sale)

            const relevantFilter = filters.find(filter =>
              (sale.marketplace === 'wb' && filter.categ === sale.subject) ||
              (['ozon', 'yandex', 'detmir'].includes(sale.marketplace) && filter.marketplace === sale.marketplace)
            );

            // Пропускаем товар, если нет соответствующего фильтра или категория отключена
            if (!relevantFilter || !relevantFilter.status_categ) return;

            // Проверка по цене
            const isPriceValid = sale.price >= relevantFilter.price_min && sale.price <= relevantFilter.price_max;
            if (!isPriceValid) return;

            if (['ozon', 'yandex', 'detmir'].includes(sale.marketplace)) {
              // Добавляем товар в список для Ozon, Yandex и Detmir
              setSales((prevSales) => [...prevSales, sale]);
              if (isSoundEnabled) playSoundRef.current();
              return;
            }

            // Проверка склада для Wildberries
            const skladText = sale.sklad || '';
            const hasWBStock = /WB - (\d+)шт\./.test(skladText);
            const hasProdStock = /ПРОД - (\d+)шт\./.test(skladText);

            // Пропускаем, если нет WB-склада и ПРОД на складе не фильтруется
            if (!hasWBStock && hasProdStock && !relevantFilter.prod) return;
            if (!hasWBStock && !hasProdStock) return;

            const isOstatokValid = sale.col >= relevantFilter.ostatok;
            if (!isOstatokValid) {
              return;
            }


            // Добавляем товар в список для Wildberries
            setSales((prevSales) => [...prevSales, sale]);
            if (isSoundEnabled) playSoundRef.current();
          });
        } else if (data.type === 'lastSales') {
          const filteredSales = data.sales.filter(sale => {
            const relevantFilter = filters.find(filter =>
              (sale.marketplace === 'wb' && filter.categ === sale.subject) ||
              (['ozon', 'yandex', 'detmir'].includes(sale.marketplace) && filter.marketplace === sale.marketplace)
            );

            if (!relevantFilter || !relevantFilter.status_categ) return false;

            const isPriceValid = sale.price >= relevantFilter.price_min && sale.price <= relevantFilter.price_max;
            if (!isPriceValid) return false;

            if (['ozon', 'yandex', 'detmir'].includes(sale.marketplace)) {
              return true;
            }

            const skladText = sale.sklad || '';
            const hasWBStock = /WB - (\d+)шт\./.test(skladText);
            const hasProdStock = /ПРОД - (\d+)шт\./.test(skladText);

            if (!hasWBStock && hasProdStock && !relevantFilter.prod) return false;
            if (!hasWBStock && !hasProdStock) return false;

            const isOstatokValid = sale.col >= relevantFilter.ostatok;
            return isOstatokValid;
          });

          setSales(filteredSales);
        } else if (data.type === 'oldSales') {
          const filteredOldSales = data.sales.filter(sale => {
            const relevantFilter = filters.find(filter =>
              (sale.marketplace === 'wb' && filter.categ === sale.subject) ||
              (['ozon', 'yandex', 'detmir'].includes(sale.marketplace) && filter.marketplace === sale.marketplace)
            );

            if (!relevantFilter || !relevantFilter.status_categ) return false;

            const isPriceValid = sale.price >= relevantFilter.price_min && sale.price <= relevantFilter.price_max;
            if (!isPriceValid) return false;

            if (['ozon', 'yandex', 'detmir'].includes(sale.marketplace)) {
              return true;
            }

            const skladText = sale.sklad || '';
            const hasWBStock = /WB - (\d+)шт\./.test(skladText);
            const hasProdStock = /ПРОД - (\d+)шт\./.test(skladText);

            if (!hasWBStock && hasProdStock && !relevantFilter.prod) return false;
            if (!hasWBStock && !hasProdStock) return false;

            const isOstatokValid = sale.col >= relevantFilter.ostatok;
            return isOstatokValid;
          });

          setSales((prevSales) => [...filteredOldSales, ...prevSales]);
        } else if (data.type === 'remaining') {
          console.log(data.dataApp)
          setSales((prevSales) => {
            // Создаем словарь для быстрого поиска данных по id
            const remainingDataMap = new Map(data.dataApp.map(item => [item[0], { col2: item[1], sklad2: item[2] }]));

            // Обновляем существующий массив sales
            return prevSales.map(sale => {
              if (remainingDataMap.has(sale.id)) {
                const { col2, sklad2 } = remainingDataMap.get(sale.id);
                return { ...sale, col2, sklad2 };
              }
              return sale;
            });
          });
        } else if (data.type === 'new_price') {
          console.log(data.dataApp)
          setSales((prevSales) => {
            // Создаем словарь для быстрого поиска данных по id
            const newPriceDataMap = new Map(data.dataApp.map(item => [item[0], { price2: item[1] }]));

            // Обновляем существующий массив sales
            return prevSales.map(sale => {
              if (newPriceDataMap.has(sale.id)) {
                const { price2 } = newPriceDataMap.get(sale.id);
                return { ...sale, price2 };
              }
              return sale;
            });
          });
        }
      };

      ws.current.onclose = (event) => {
        console.log('WebSocket disconnected.', event);
        setIsConnected(false);
        setConnectionStatus('disconnected');
        setSales([]);

        if (event.code === 1008 && event.reason === 'subscription ended') {
          console.log('Subscription ended.');
          setSubscriptionEnded(true);
          setShouldReconnect(false);

        } else if (event.code === 1008 && event.reason === 'logged in to another device') {
          setShouldReconnect(false);
          logout()

        } else if (shouldReconnect && !document.hidden && !event.wasClean) {
          console.log('Attempting to reconnect...');
          reconnectTimeout.current = setTimeout(connectWebSocket, 1000);
        }
      };

      ws.current.onerror = (error) => {
        console.error('WebSocket error:', error);
        ws.current.close();
        setIsConnected(false);
        setConnectionStatus('error');
      };
    };

    const handleVisibilityChange = () => {
      if (document.hidden) {
        if (ws.current) {
          console.log('Document hidden, closing WebSocket.');
          ws.current.close();
        }
        setIsConnected(false);
        setConnectionStatus('disconnected');
      } else {
        console.log('Document visible, connecting WebSocket.');
        connectWebSocket();
      }
    };

    document.addEventListener('visibilitychange', handleVisibilityChange);

    connectWebSocket();

    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
      setShouldReconnect(false);
      clearTimeout(reconnectTimeout.current);
      if (ws.current) {
        ws.current.close();
      }
    };
  }, [filters, token, userInfo]);

  const fetchMoreSales = (firstId) => {
    return new Promise((resolve, reject) => {
      if (isFetchingRef.current) {
        reject('Already fetching');
        return;
      }

      if (ws.current && ws.current.readyState === WebSocket.OPEN) {
        isFetchingRef.current = true;
        ws.current.send(JSON.stringify({ type: 'getOldSales', id: firstId }));
        resolve();
      } else {
        reject('WebSocket is not open');
      }
    }).finally(() => {
      isFetchingRef.current = false;
    });
  };



  return (
    <WebSocketContext.Provider value={{ ws: ws.current, isConnected, connectionStatus, subscriptionEnded, sales, fetchMoreSales }}>
      {children}
    </WebSocketContext.Provider>
  );
};

export const useWebSocket = () => useContext(WebSocketContext);

const ConnectionStatus = () => {
  const { theme } = useTheme();
  const { isConnected, connectionStatus, subscriptionEnded } = useWebSocket();
  const { userInfo } = useAuth();

  const themeClass = theme === 'dark' ? 'text-white bg-dark' : 'text-dark bg-light';
  const headerStyle = {
    borderRadius: '10px',
    padding: '20px',
    margin: '10px',
    backgroundColor: theme === 'dark' ? '#212529' : '#f8f9fa',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
  };

  return (
    <>
      {!isConnected && userInfo?.status && !subscriptionEnded && (
        <Alert variant="warning" className="text-center" style={{ margin: '10px' }}>
          Устанавливаем связь с космосом...
        </Alert>
      )}
      {subscriptionEnded && (
        <Container className={`pt-3 pb-5 ${themeClass}`}>
          <Row className="mb-3">
            <Col>
              <Card className={`d-flex align-items-center justify-content-center text-center ${themeClass} shadow-lg`} style={headerStyle}>
                <Card.Body>
                  <Card.Title>Подписка закончилась</Card.Title>
                  <Card.Text>Пожалуйста, оформите подписку, чтобы продолжить получать скидки.</Card.Text>
                  <Button onClick={() => window.location.href = '/subscribe'} variant="primary">
                    Оплатить подписку
                  </Button>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
      )}
      {!userInfo?.status && !subscriptionEnded && (
        <Container className={`pt-3 pb-5 ${themeClass}`}>
          <Row className="mb-3">
            <Col>
              <Card className={`d-flex align-items-center justify-content-center text-center ${themeClass} shadow-lg`} style={headerStyle}>
                <Card.Body>
                  <Card.Title>У вас неактивна подписка</Card.Title>
                  <Card.Text>Пожалуйста, оформите подписку, чтобы продолжить получать скидки.</Card.Text>
                  <Button onClick={() => window.location.href = '/subscribe'} variant="primary">
                    Оплатить подписку
                  </Button>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
      )}
    </>
  );
};

export default ConnectionStatus;

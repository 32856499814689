import React, { useState, useEffect } from 'react';
import './App.css';
import { useTheme } from './ThemeContext';
import BottomNav from './components/BottomNav';
import SalesFeed from './components/SalesFeed';
import ConnectionStatus, { WebSocketProvider,useWebSocket } from './components/WebSocketContext';

function App() {
  const { theme } = useTheme();
  const themeClass = theme === 'dark' ? 'bg-dark text-white' : 'bg-light text-dark';
  document.body.className = `App ${themeClass}`;

  return (
    <WebSocketProvider>
      <MainContent />
    </WebSocketProvider>
  );
}

const MainContent = () => {
  const { sales } = useWebSocket();
  const [salesData, setSalesData] = useState([]);

  useEffect(() => {
    if (sales !== null) {
      setSalesData(sales);
    }
  }, [sales]);

  return (
    <div className="main-content">
      <ConnectionStatus />
      <SalesFeed sales={salesData} type='ws' />
      <BottomNav />
    </div>
  );
};

export default App;

import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Button, Modal, CloseButton, Card, Form } from 'react-bootstrap';
import BottomNav from '../BottomNav';
import { useTheme } from '../../ThemeContext';
import { VolumeUp, VolumeMute, MoonStars, Sliders, Sun, Share } from 'react-bootstrap-icons';
import Codee from '../ui/Codee';
import { useAuth } from '../AuthContext';

const ProfilePage = () => {
  const { theme, toggleTheme } = useTheme();
  const { filters, getFilters, updateFilters, userInfo, getPrig, referalInfo, isAuthenticated, isLoading, logout, isSoundEnabled, toggleSound } = useAuth();
  const [modalShow, setModalShow] = useState(false);
  const [showFiltersModal, setShowFiltersModal] = useState(false);
  const [localFilters, setLocalFilters] = useState([]);
  const [isModified, setIsModified] = useState(false);
  const [errors, setErrors] = useState([]);
  const [notifToken, setNotifToken] = useState();
  const [p256dh, setP256dh] = useState(null);
  const [auth, setAuth] = useState(null);

  useEffect(() => {
    const initializeAuth = async () => {
      if (isAuthenticated && !isLoading) {
        await getPrig();
        await getFilters();
      }
    };
    initializeAuth();
  }, []);
  useEffect(() => {
    if (filters) {
      setLocalFilters(filters);
    }
  }, [filters]);

  const themeClass = theme === 'dark' ? 'text-white bg-dark' : 'text-dark bg-light';

  const subscriptionStatus = userInfo.status
    ? <div className={`subscription-status basic-subscription`}>Подписка Активна до {userInfo.until} по МСК </div>
    : <div className={`subscription-status no-subscription`}>Подписка закончилась {userInfo.until} по МСК </div>;

  const copyRefLink = () => {
    navigator.clipboard.writeText(`https://t.me/SaleW_bot?start=${userInfo.user_id}`)
      .then(() => alert("Ссылка скопирована!"))
      .catch((err) => console.error('Ошибка при копировании: ', err));
  };

  const handleShare = async () => {
    const shareData = {
      title: 'Присоединяйтесь к SaleW боту!',
      text: 'Переходи по ссылке и покупай подписку!',
      url: `https://t.me/SaleW_bot?start=${userInfo.user_id}`,
    };

    try {
      if (navigator.share) {
        await navigator.share(shareData);
      } else {
        await navigator.clipboard.writeText(shareData.url);
        alert('Ссылка скопирована в буфер обмена!');
      }
    } catch (err) {
      console.error('Ошибка при попытке поделиться: ', err);
    }
  };
  const getServiceWorkerRegistration = async () => {
    return new Promise((resolve, reject) => {
      const timeout = setTimeout(() => {
        reject('Service Worker registration timed out');
      }, 5000); // Тайм-аут 5 секунд

      navigator.serviceWorker.ready.then((registration) => {
        clearTimeout(timeout);
        resolve(registration);
      }).catch((error) => {
        clearTimeout(timeout);
        reject('Service Worker registration failed: ' + error);
      });
    });
  };


  const handleFilterChange = async (index, field, value) => {
    const updatedFilters = [...localFilters];
    updatedFilters[index][field] = value;
    setLocalFilters(updatedFilters);
    setIsModified(true);

    if (field === 'push' && value === true) {
      try {
        const permission = await Notification.requestPermission();
        if (permission !== 'granted') {
          alert('Разрешение на получение уведомлений не предоставлено');
          updatedFilters[index].push = false;
          setLocalFilters(updatedFilters);
          return;
        }

        const registration = await getServiceWorkerRegistration();

        let subscription = await registration.pushManager.getSubscription();

        if (!subscription) {
          subscription = await registration.pushManager.subscribe({
            userVisibleOnly: true,
            applicationServerKey: 'BDM2nT8SK7-krQAvAdLxT8kwsoqo1oyNI4eKSM79RFn9fUlDcmo8dMxwDcvIlr_RhZygip8b-HabF7gJGok9veo'
          });
        }
        const notifToken = subscription.endpoint;
        const p256dh = btoa(String.fromCharCode.apply(null, new Uint8Array(subscription.getKey('p256dh'))));
        const auth = btoa(String.fromCharCode.apply(null, new Uint8Array(subscription.getKey('auth'))));
        setNotifToken(notifToken)
        setP256dh(p256dh)
        setAuth(auth)

        setLocalFilters(updatedFilters);


      } catch (err) {
        console.error('Ошибка при подписке на уведомления: ', err);
      }
    }

    validateFilter(updatedFilters[index], index);
  };





  const validateFilter = (filter, index) => {
    const error = {};

    // Валидация цены
    if (filter.price_min > filter.price_max) {
      error.price = "Минимальная цена не может быть больше максимальной";
    }

    // Валидация остатка
    if (filter.ostatok !== undefined && filter.ostatok < 0) {
      error.ostatok = "Остаток не может быть отрицательным";
    }

    // Обновляем ошибки для текущего фильтра
    const newErrors = [...errors];
    newErrors[index] = error;
    setErrors(newErrors);

    // Если ошибок нет, возвращаем true
    return Object.keys(error).length === 0;
  };



  const handleSaveFilters = async () => {
    const isValid = localFilters.every((filter, index) => validateFilter(filter, index));
    if (isValid) {
      await updateFilters(localFilters, notifToken, p256dh, auth);
      setShowFiltersModal(false);
    } else {
      alert("Пожалуйста, исправьте ошибки перед сохранением");
    }
  };

  const handleModalClose = () => {
    if (isModified) {
      if (window.confirm('Сохранить изменения перед закрытием?')) {
        const isValid = localFilters.every((filter, index) => validateFilter(filter, index));
        if (isValid) {
          handleSaveFilters(); // Сохраняем и закрываем окно
          setShowFiltersModal(false); // Закрываем окно только если все валидно
        } else {
          alert("Пожалуйста, исправьте ошибки перед закрытием.");
          // Окно останется открытым для исправления ошибок
        }
      } else {
        setShowFiltersModal(false); // Закрываем окно без сохранения
      }
    } else {
      setShowFiltersModal(false); // Закрываем, если не было изменений
    }
  };

  return (
    <>
      <Container className={`pt-3 pb-5 ${themeClass} scrollable`}>
        <Row className="mb-3">
          <Col>
            <Card className={`shadow-lg ${themeClass}`}>
              <Card.Body className="d-flex align-items-center justify-content-between">
                <h4 className="mb-0">ID: <Codee>{userInfo.user_id}</Codee></h4>
                <Button onClick={() => window.location.href = '/subscribe'} variant="primary">
                  Оплатить подписку
                </Button>
              </Card.Body>
              <Card.Footer className="text-center">
                {subscriptionStatus}
              </Card.Footer>
            </Card>
          </Col>
        </Row>

        <Row className="mb-3">
          <Col>
            <div className={`${themeClass} shadow-lg`} style={{ padding: '1rem', borderRadius: '0.5rem' }}>
              <h2 className="text-center">Реферальная программа</h2>
              <div className={`d-flex justify-content-around`}>
                <div style={{ alignItems: 'center', justifyContent: 'center', width: '100%', padding: '0 10px' }}>
                  <h1 className="text-center" style={{ fontWeight: 800 }}>{referalInfo ? referalInfo[0] : null}</h1>
                  <h6 className="text-center" style={{ color: 'grey', fontWeight: 200 }}>Рефералов</h6>
                </div>
                <div style={{ alignItems: 'center', justifyContent: 'center', width: '100%', padding: '0 10px' }}>
                  <h1 className="text-center" style={{ fontWeight: 800 }}>{referalInfo ? referalInfo[1] : null}</h1>
                  <h6 className="text-center" style={{ color: 'grey', fontWeight: 200 }}>Активных подписок</h6>
                </div>
                <div style={{ alignItems: 'center', justifyContent: 'center', width: '100%', padding: '0 10px' }}>
                  <h1 className="text-center" style={{ fontWeight: 800 }}>{userInfo.balance}₽</h1>
                  <h6 className="text-center" style={{ color: 'grey', fontWeight: 200 }}>Баланс</h6>
                </div>
              </div>

              <div className={`d-flex justify-content-around`} style={{ marginTop: '30px' }}>
                <Button variant="outline-primary" onClick={handleShare} className="mx-2" style={{ alignItems: 'center', justifyContent: 'center', width: '100%', padding: '0 10px', height: '70px' }}>
                  Поделиться ссылкой <Share size={20} />
                </Button>
                <Button variant="outline-primary" onClick={() => setModalShow(true)} className="mx-2" style={{ alignItems: 'center', justifyContent: 'center', width: '100%', padding: '0 10px', height: '70px' }}>
                  Условия
                </Button>
              </div>
            </div>
          </Col>
        </Row>

        <Row className="mb-3">
          <Col>
            <div className={`profile-content rounded ${themeClass} shadow-lg`} style={{ padding: '1rem', borderRadius: '0.5rem', minHeight: '200px' }}>
              <h2 className="text-center">Профиль WB</h2>
              {/* Сюда вставляется информация профиля пользователя */}
            </div>
          </Col>
        </Row>

        <Row className="mb-3">
          <Col>
            <div className={`d-flex justify-content-around ${themeClass} shadow-lg`} style={{ borderRadius: '0.5rem', minHeight: '100px' }}>
              <Button variant="transparent" onClick={toggleSound} style={{ alignItems: 'center', justifyContent: 'center', width: '100%', padding: '0 10px' }} className={themeClass}>
                {isSoundEnabled ? <VolumeUp size={40} /> : <VolumeMute size={40} />}
              </Button>
              <Button variant="transparent" style={{ alignItems: 'center', justifyContent: 'center', width: '100%', padding: '0 10px' }} className={themeClass} onClick={toggleTheme}>
                {theme === 'dark' ? <Sun size={40} /> : <MoonStars size={40} />}
              </Button>
              <Button variant="transparent" style={{ alignItems: 'center', justifyContent: 'center', width: '100%', padding: '0 10px' }} className={themeClass} onClick={() => setShowFiltersModal(true)}>
                <Sliders size={40} />
              </Button>
            </div>
          </Col>
        </Row>

        <Row className="mb-3">
          <Col>
            <div className={`d-flex justify-content-center`} style={{ borderRadius: '0.5rem', minHeight: '100px' }}>
              <Button variant="outline-danger" onClick={() => logout()} className="mx-2" style={{ alignItems: 'center', justifyContent: 'center', width: '30%', padding: '0 10px', height: '40px', marginTop: '20px' }}>Выйти</Button>
            </div>
          </Col>
        </Row>
      </Container>

      <BottomNav />

      <Modal
        show={modalShow}
        onHide={() => setModalShow(false)}
        className={theme === 'dark' ? 'text-white modal-dark' : 'text-dark modal-light'}
      >
        <Modal.Header className={theme === 'dark' ? 'border-bottom border-secondary bg-dark text-white' : 'border-bottom border-secondary bg-light text-black'}>
          <Modal.Title>Реферальная программа</Modal.Title>
          <CloseButton onClick={() => setModalShow(false)} className={theme === 'dark' ? 'btn-close-white' : 'btn-close-black'} />
        </Modal.Header>
        <Modal.Body className={theme === 'dark' ? 'bg-dark text-white' : 'text-black bg-light'}>
          <p><strong>За каждую оплату подписки приглашенного Вами человека,</strong> на Ваш бонусный счёт начисляется <strong>25% от суммы покупки подписки</strong> приглашенного!</p>
          <p>
            А также вы и приглашенный получаете <strong>+2 дня подписки</strong> за каждого нового пользователя, который впервые заходит в бота по вашей реферальной ссылке.
          </p>
          <p>Вывести вы сможете на любую карту российского банка, в боте <a href="https://t.me/salew_bot" target="_blank" rel="noopener noreferrer" className={theme === 'dark' ? 'text-white' : 'text-dark'}>@Salew_Bot</a>, после того как накопится более 500 руб. за приглашения</p>
          <p>Приглашенным считается тот, кто первый раз перешел в бот по вашей реферальной ссылке!</p>
          <p>Материалы для рекламы вы можете использовать из канала <a href="https://t.me/salew_catch" target="_blank" rel="noopener noreferrer" className={theme === 'dark' ? 'text-white' : 'text-dark'}>@salew_catch</a></p>
          <p className="text-warning">Внимание: В период акционных предложений за оплату подписки по специальной цене реферальные бонусы не начисляются, если иное не определено условиями акции</p>
        </Modal.Body>
      </Modal>

      <Modal
        show={showFiltersModal}
        onHide={handleModalClose}
        className={theme === 'dark' ? 'text-white modal-dark' : 'text-black modal-light'}
        size="lg"
      >
        <Modal.Header className={theme === 'dark' ? 'border-bottom border-secondary bg-dark text-white' : 'border-bottom border-secondary bg-light text-black'}
        >
          <Modal.Title>Фильтры</Modal.Title>
          <CloseButton
            onClick={handleModalClose}
            className={theme === 'dark' ? 'btn-close-white' : 'btn-close-black'}
          />
        </Modal.Header>
        <Modal.Body className={theme === 'dark' ? 'bg-dark text-white' : 'bg-light text-black'}>
          <Container fluid>
            <Row>
              {localFilters.map((filter, index) => (
                <Col key={index} xs={12} sm={6} md={6} lg={6} xl={4} className="mb-4">
                  <Card className={`h-100 ${theme === 'dark' ? 'transparent-black' : 'transparent-white'}`} style={{ paddingTop: '0px', opacity: filter.status_categ ? 1 : 0.3 }}>
                    <Card.Body>
                      <Form>
                        <Form.Group controlId={`filter-${index}`}>
                          <Form.Label style={{ fontWeight: 'bold', fontSize: '1.2rem', textAlign: 'center', display: 'block' }}>
                            {filter.categ}
                            <Form.Check
                              type="switch"
                              id={`status-categ-${index}`}
                              checked={filter.status_categ}
                              onChange={(e) => handleFilterChange(index, 'status_categ', e.target.checked)}
                            />
                          </Form.Label>


                          <Form.Check
                            type="switch"
                            id={`push-${index}`}
                            label="Push уведомления"
                            checked={filter.push}
                            onChange={(e) => handleFilterChange(index, 'push', e.target.checked)}
                          />

                          <Form.Label>Цена (мин - макс)</Form.Label>
                          <div className="d-flex">
                            <Form.Control
                              type="number"
                              placeholder="Мин"
                              value={filter.price_min}
                              onChange={(e) => handleFilterChange(index, 'price_min', parseInt(e.target.value))}
                              className="me-2"
                              isInvalid={errors[index]?.price} // Показываем ошибку
                            />
                            <Form.Control
                              type="number"
                              placeholder="Макс"
                              value={filter.price_max}
                              onChange={(e) => handleFilterChange(index, 'price_max', parseInt(e.target.value))}
                              isInvalid={errors[index]?.price} // Показываем ошибку
                            />
                          </div>
                          {errors[index]?.price && <div className="text-danger">{errors[index]?.price}</div>}

                          {/* Валидация остатка */}
                          {filter.categ !== 'Ozon' && filter.categ !== 'Detmir' && filter.categ !== 'Yandex' && (
                            <>
                              <Form.Label>Остаток</Form.Label>
                              <Form.Control
                                type="number"
                                placeholder="От"
                                value={filter.ostatok}
                                onChange={(e) => handleFilterChange(index, 'ostatok', parseInt(e.target.value))}
                                isInvalid={errors[index]?.ostatok} // Показываем ошибку для остатка
                              />

                              <Form.Check
                                type="switch"
                                id={`prod-${index}`}
                                label="Склад продавца"
                                checked={filter.prod}
                                onChange={(e) => handleFilterChange(index, 'prod', e.target.checked)}
                              />
                              {errors[index]?.ostatok && <div className="text-danger">{errors[index]?.ostatok}</div>}
                            </>
                          )}
                        </Form.Group>

                      </Form>
                    </Card.Body>
                  </Card>
                </Col>
              ))}
            </Row>
          </Container>
        </Modal.Body>

      </Modal>
    </>
  );
};

export default ProfilePage;

import React, { createContext, useContext, useState, useEffect } from 'react';
import { JS0N } from './ui/LoadingIndicator';

const AuthContext = createContext();


export const AuthProvider = ({ children }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [token, setToken] = useState(null);
  const [userInfo, setUserInfo] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [referalInfo, setReferalInfo] = useState(null);
  const [isSoundEnabled, setIsSoundEnabled] = useState(() => localStorage.getItem('sound') === 'enabled');
  const [filters, setFilters] = useState(null);
  const [push, setPush] = useState();
  const [notifToken, setNotifToken] = useState();
  const [p256dh, setP256dh] = useState(null);
  const [auth, setAuth] = useState(null);

  const getServiceWorkerRegistration = async () => {
    return new Promise((resolve, reject) => {
      const timeout = setTimeout(() => {
        reject('Service Worker registration timed out');
      }, 5000); // Тайм-аут 5 секунд

      navigator.serviceWorker.ready.then((registration) => {
        clearTimeout(timeout);
        resolve(registration);
      }).catch((error) => {
        clearTimeout(timeout);
        reject('Service Worker registration failed: ' + error);
      });
    });
  };

  const apiUrl = 'https://pandoras-box.salew-bot.ru'
  const validateToken = async () => {
    const token = localStorage.getItem('token');
    // await sleep(5000);
    if (token) {
      const responseData = await checkTokenValidity(token);

      if (responseData.status) {
        setIsAuthenticated(true);
        setToken(responseData.token);
        setUserInfo(responseData.user_info)
        localStorage.setItem('token', responseData.token);
      }
    }
    setIsLoading(false); // Завершение загрузки
  };



  const login = async (telegramData) => {
    try {
      telegramData.userAgent = navigator.userAgent;
      const serverResponse = await fetch(`${apiUrl}/check_telegram_auth`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(telegramData),
      });

      if (!serverResponse.ok) {
        throw new Error('Ошибка при запросе к API');
      }
      const responseData = await serverResponse.json();
      if (serverResponse.ok && responseData.token) {
        setIsAuthenticated(true);
        setToken(responseData.token);
        setUserInfo(responseData.user_info)
        localStorage.setItem('token', responseData.token);

        try {
          const permission = await Notification.requestPermission();
          if (permission !== 'granted') {
            alert('Разрешение на получение уведомлений не предоставлено');
            setPush(false)
            try {

              let currentToken = responseData.token;
              let response = await fetch(`${apiUrl}/filters/update_push`, {
                method: 'PUT',
                headers: {
                  'Content-Type': 'application/json',
                  'Authorization': `Bearer ${currentToken}`
                }
              });


              if (!response.ok) {
                throw new Error('Ошибка при обновлении фильтров');
              }

            } catch (error) {
              console.error('Ошибка при обновлении фильтров:', error);
            }
            return;
          }

          const registration = await getServiceWorkerRegistration();

          let subscription = await registration.pushManager.getSubscription();

          if (!subscription) {
            subscription = await registration.pushManager.subscribe({
              userVisibleOnly: true,
              applicationServerKey: 'BDM2nT8SK7-krQAvAdLxT8kwsoqo1oyNI4eKSM79RFn9fUlDcmo8dMxwDcvIlr_RhZygip8b-HabF7gJGok9veo'
            });
          }
          const notifToken = subscription.endpoint;
          const p256dh = btoa(String.fromCharCode.apply(null, new Uint8Array(subscription.getKey('p256dh'))));
          const auth = btoa(String.fromCharCode.apply(null, new Uint8Array(subscription.getKey('auth'))));
          setNotifToken(notifToken)
          setP256dh(p256dh)
          setAuth(auth)

          try {

            let currentToken = responseData.token;
            let response = await fetch(`${apiUrl}/filters/update_enpoints`, {
              method: 'PUT',
              headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${currentToken}`,
              },
              body: JSON.stringify({ endpoint: notifToken, p256dh: p256dh, auth: auth }),
            });



            if (!response.ok) {
              throw new Error('Ошибка при обновлении фильтров');
            }

            const data = await response.json();
            setFilters(data.filters);
          } catch (error) {
            console.error('Ошибка при обновлении фильтров:', error);
          }


        } catch (err) {
          console.error('Ошибка при подписке на уведомления: ', err);
        }

      } else {
        setShowModal(true);

      }
    } catch (error) {
      console.error('Ошибка аутентификации:', error);
    }
  };

  const logout = () => {
    setIsAuthenticated(false);
    setToken(null);
    setUserInfo(null)
    setReferalInfo(null)
    localStorage.removeItem('token');
  };


  const checkTokenValidity = async (token) => {
    try {
      const response = await fetch(`${apiUrl}/pandoras-echo`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        body: JS0N.stringify(token),
      });
      if (response.ok) {
        const responseData = await response.json();
        return responseData
      } else {
        return { status: false }
      }

    } catch (error) {
      console.error('Error validating token:', error);
      return false;
    }
  };

  const getPrig = async () => {
    try {
      let currentToken = token;  // Используем текущий токен
      let serverResponse = await fetch(`${apiUrl}/pandoras-trace`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${currentToken}`
        },
      });

      if (serverResponse.status === 401) {
        await validateToken();
        currentToken = localStorage.getItem('token');  // Получаем новый токен
        serverResponse = await fetch(`${apiUrl}/pandoras-trace`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${currentToken}`
          },
        });
      }

      if (!serverResponse.ok) {
        throw new Error(`Ошибка при запросе: ${serverResponse.statusText}`);
      }

      const data = await serverResponse.json();
      setReferalInfo(data.referals);
    } catch (error) {
      console.error('Ошибка при получении данных:', error);
      throw error;
    }
  };



  const payWithBonus = async () => {
    setIsLoading(true);
    try {
      let currentToken = token;
      const response = await fetch(`${apiUrl}/pandoras-pay-bonus`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${currentToken}`
        }
      });

      if (response.status === 401) {
        await validateToken();
        currentToken = localStorage.getItem('token');  // Обновление токена из localStorage
        response = await fetch(`${apiUrl}/pandoras-pay-bonus`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${currentToken}`
          }
        });
      } else if (!response.ok) {
        throw new Error('Ошибка при оплате бонусами');
      }

      const data = await response.json();
      return data;
    } catch (error) {
      console.error('Ошибка при оплате бонусами:', error);
      throw error;
    } finally {
      setIsLoading(false);
    }
  };

  const payWithRegular = async (email, days, amount) => {
    setIsLoading(true);
    try {
      let currentToken = token;  // Локальная переменная для токена
      let response = await fetch(`${apiUrl}/pandoras-pay`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${currentToken}`
        },
        body: JSON.stringify({ email, days, amount })
      });

      if (response.status === 401) {
        await validateToken();
        currentToken = localStorage.getItem('token');  // Обновление токена из localStorage
        response = await fetch(`${apiUrl}/pandoras-pay`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${currentToken}`
          },
          body: JSON.stringify({ email, days, amount })
        });
      }

      if (!response.ok) {
        throw new Error('Ошибка при обычной оплате');
      }

      const data = await response.json();
      return data;
    } catch (error) {
      console.error('Ошибка при обычной оплате:', error);
      throw error;
    } finally {
      setIsLoading(false);
    }
  };


  const searchSales = async (type, value) => {

    try {
      let currentToken = token;  // Локальная переменная для токена
      let response = await fetch(`${apiUrl}/search-sales`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${currentToken}`
        },
        body: JSON.stringify({ type: type, value: value })
      });

      if (response.status === 401) {
        await validateToken();
        currentToken = localStorage.getItem('token');  // Обновление токена из localStorage
        response = await fetch(`${apiUrl}/search-sales`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${currentToken}`
          },
          body: JSON.stringify({ type: type, value: value })
        });
      }

      if (!response.ok) {
        throw new Error('Ошибка');
      }

      const data = await response.json();
      return data;
    } catch (error) {
      console.error('Ошибка', error);
      throw error;
    } finally {
    }
  };


  const getFilters = async () => {
    try {
      let currentToken = token;
      let response = await fetch(`${apiUrl}/filters`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${currentToken}`
        }
      });

      if (response.status === 401) {
        await validateToken();
        currentToken = localStorage.getItem('token');
        response = await fetch(`${apiUrl}/filters`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${currentToken}`
          }
        });
      }

      if (!response.ok) {
        throw new Error('Ошибка при получении фильтров');
      }

      const data = await response.json();
      setFilters(data.filters);
    } catch (error) {
      console.error('Ошибка при получении фильтров:', error);
    }
  };

  const updateFilters = async (updatedFilters, notifToken, p256dh, auth) => {
    try {
      const sanitizedFilters = updatedFilters.map(({ id, user_id, ...rest }) => rest);

      let currentToken = token;
      let response = await fetch(`${apiUrl}/filters/update`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${currentToken}`
        },
        body: JSON.stringify({ filters: sanitizedFilters, endpoint: notifToken, p256dh: p256dh, auth: auth })
      });

      if (response.status === 401) {
        await validateToken();
        currentToken = localStorage.getItem('token');
        response = await fetch(`${apiUrl}/filters/update`, {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${currentToken}`
          },
          body: JSON.stringify({ filters: sanitizedFilters, endpoint: notifToken, p256dh: p256dh, auth: auth })
        });
      }

      if (!response.ok) {
        throw new Error('Ошибка при обновлении фильтров');
      }

      const data = await response.json();
      setFilters(data.filters);
    } catch (error) {
      console.error('Ошибка при обновлении фильтров:', error);
    }
  };


  const toggleSound = () => {
    const newSoundState = !isSoundEnabled;
    setIsSoundEnabled(newSoundState);
    localStorage.setItem('sound', newSoundState ? 'enabled' : 'disabled');
  };

  useEffect(() => {
    const initializeAuth = async () => {
      await validateToken();

    };

    initializeAuth();
  }, []);

  return (
    <AuthContext.Provider value={{ isLoading, setIsLoading, isAuthenticated, token, userInfo, login, logout, showModal, setShowModal, getPrig, referalInfo, payWithBonus, payWithRegular, isSoundEnabled, toggleSound, searchSales, getFilters, updateFilters, filters }}>
      {children}
    </AuthContext.Provider>
  );
};



export const useAuth = () => useContext(AuthContext);
